import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import FormLayout from "../components/FormLayout";
import Seo from "../components/Seo";
import FormModule from "../modules/Form";

const FormTemplate = (props) => {
  const post = props.data.post;
  const [isLoading, setIsLoading] = useState(false);
  const [sideImage, setSideImage] = useState(null);
  
  useEffect(() => {
    document.body.classList.remove("booking-menu-open");
  }, []);

  return (
    <FormLayout isLoading={isLoading} sideImage={sideImage}>
      <FormModule modules={post.pageContent} setIsLoading={setIsLoading} setSideImage={(image) => setSideImage(image)} />
    </FormLayout>
  );
};

export default FormTemplate;
export const formQuery = graphql`
  query formPageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      title
      ...FormFragment
    }
  }
`;
