import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FormLayout = ({ isLoading, sideImage, ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isImage, setisImage] = useState(null);

  useEffect(() => {
    document.body.classList.remove("booking-menu-open");
    document.body.classList.remove("nav-menu-open");
  }, []);

  const query = useStaticQuery(graphql`
    query {
      secondaryMenu: wpMenu(slug: { eq: "secondary-menu" }) {
        id
        slug
        name
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      secondaryInnerMenu: wpMenu(slug: { eq: "secondary-inner-menu" }) {
        id
        slug
        name
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      settings: wp {
        fluxDnaSettings {
          fluxDna {
            socialMedia {
              url
              icon {
                altText
                title
                mediaItemUrl
                uri
              }
            }
            primaryLogo {
              gatsbyImage(formats: WEBP, placeholder: NONE, width: 400)
              altText
            }
            logoBlack {
              gatsbyImage(formats: WEBP, placeholder: NONE, width: 400)
            }
            travelEnquiryBgimage {
              altText
              mediaItemUrl
              gatsbyImage(width: 1000, formats: WEBP)
              uri
              title
            }
          }
        }
      }
      accommodations: allWpAccommodation {
        nodes {
          id
          slug
          title
        }
      }
    }  
  `);

  const settings = query?.settings?.fluxDnaSettings?.fluxDna;

  const callback = (image) => {
    if (image) {
      setisImage(image);
    }
  };

  return (
    <>
      {isLoading && (
        <div
          id="loading"
          className="loading-spinner
      "
        ></div>
      )}
      <section className="traveller-enquiry-form bg-black-200">
        <div className="flex flex-wrap">
          <div className="lg:w-7/12 w-full closemenu">
            <div className="xl:px-80 lg:px-40 px-20 lg:py-40 pb-20 pt-20 relative h-screen overflow-y-auto overflow-x-hidden enquiry-left lgscreen:h-auto">
              <div className="enquiry-header mb-50">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="logo relative z-999">
                    <Link to="/">
                      <GatsbyImage
                        image={getImage(settings?.primaryLogo)}
                        alt={settings?.primaryLogo?.altText}
                        className="m-auto lg:w-[149px] w-[120px] desktop-logo"
                      />
                    </Link>
                  </div>
                  <div className="header-top-right flex ">
                    <div
                      onClick={() => {
                        document.body.classList.toggle("nav-menu-open");
                        // setMenuOpen(!menuOpen);
                      }}
                      className={`navbar-icon flex flex-col items-end cursor-pointer hamburger menu-open-test space-y-2 menu-line  ${menuOpen ? "active" : ""
                        }`}
                    >

                      <span className="line w-full"></span>
                      <span className="line line-middle w-full"></span>
                      <span className="line w-full"></span>

                    </div>

                    <div
                      onClick={() => {
                        document.body.classList.toggle("nav-menu-open");
                        // setMenuOpen(!menuOpen);
                      }}
                      className={`menu-close hidden cursor-pointer relative z-99 ${menuOpen ? "active" : ""
                        }`}
                    >
                      <img src="/images/close.png" className="max-w-25 mdscreen:max-w-20" alt="" />
                    </div>

                  </div>
                </div>
              </div>
              
              {props.children}
              
            </div>
          </div>
          <div className="lg:w-5/12 w-full">
            <div className="img h-full">
              <GatsbyImage 
                className="w-full h-screen object-cover"
                image={getImage(sideImage)}
                alt={sideImage?.altText}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="main-nav nav-container">
        <div className="menu-overlay h-full fixed w-full top-0 left-0 bg-gray2"></div>
        <div className="mainNavimg mdscreen:hidden">
          {isImage && (
            <GatsbyImage
              image={getImage(isImage)}
              alt=""
              className="ml-auto mainNavbgImg"
            ></GatsbyImage>
          )}
        </div>

        <div className="main-nav nav-container">
          <div className="mainNavimg relative mdscreen:hidden">
            <img src="/images/menu-bg.jpg" className="mainNavbgImg" alt="" />
          </div>

          <div className="mainNav__wrap relative m-auto p-menu_mobile z-99 h-full">
            <div className="container-fluid h-full">
              <div className="flex flex-wrap items-center justify-end min-h-full mobile-menu mdscreen:justify-start">
                <div className="md:w-5/12 mdscreen:w-full pt-50">
                  <div className="mainNavGrid">
                    <div className="mainNav__col navMenu text-right mdscreen:text-left relative mainNav__col--right flex flex-col items-end mdscreen:items-start pr-60">
                      <ul className="navMenuLeft topMenu visible list-none m-0 p-0 space-y-2">
                        {query?.secondaryMenu?.menuItems?.nodes?.length > 0 &&
                          query?.secondaryMenu?.menuItems?.nodes.map(
                            (menuItem) => (
                              <li key={menuItem.id}>
                                <Link
                                  to={menuItem?.path}
                                  target={menuItem?.target}
                                >
                                  {menuItem?.label}
                                </Link>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                    <div className="navbar-btm-menu text-right mdscreen:text-left pr-60 pt-20">
                      <ul className=" space-y-2 relative pt-20">
                        {query?.secondaryInnerMenu?.menuItems?.nodes?.length >
                          0 &&
                          query?.secondaryInnerMenu?.menuItems?.nodes.map(
                            (menuItem) => (
                              <li key={menuItem.id}>
                                <Link
                                  to={menuItem?.path}
                                  target={menuItem?.target}
                                >
                                  {menuItem?.label}
                                </Link>
                              </li>
                            )
                          )}
                      </ul>
                      <div className="sicon py-15 mt-15">
                        <ul className="flex flex-wrap justify-end space-x-3 mdscreen:justify-start">
                          {settings.socialMedia.map((item, index) => (
                            <li className="cursor-pointer" key={index.toString()}>
                              <Link target="_blank" to={item.url}>
                                <img src={item.icon.mediaItemUrl} alt="" />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormLayout;