import React from "react";
import { graphql } from "gatsby";
import GuestEnquiryForm from "../components/GuestEnquiryForm";
import JourneyEquiryForm from "../components/JourneyEnquiryForm";
import PrivateSafariEnquiryForm from "../components/PrivateSafariEnquiryForm";

const FormModule = ({ setIsLoading, setSideImage, ...props }) => {
  const modules = props.modules.pageContent;


  const renderForm = (module, index) => {
    const moduleName = module.__typename.replace(
      "WpPage_Pagecontent_PageContent_",
      ""
    );
    setSideImage(module?.sideImage);
    switch(moduleName) {
      case "GuestEnquiryForm": {
        return <GuestEnquiryForm formId={module?.formShortcode} setIsLoading={setIsLoading} key={index.toString()} />
      }
      case "PrivateSafariEnquiryForm": {
        return  <PrivateSafariEnquiryForm formId={module?.formShortcode} setIsLoading={setIsLoading} key={index.toString()} />
      }
      case "JourneyEnquiryForm": {
        return <JourneyEquiryForm formId={module?.formShortcode} setIsLoading={setIsLoading} key={index.toString()} />
      }
      default: {
        return null
      }
    }
  }

  return modules && modules?.length > 0 ? modules.map(renderForm) : null;
};

export default FormModule;

export const FormFragment = graphql`
  fragment FormFragment on WpPage {
    pageContent {
      pageContent {
        __typename
        ...GuestEnquiryFormFragment
        ...PrivateSafariEnquiryFormFragment
        ...JourneyEnquiryFormFragment
      }
    }
  }
`;
