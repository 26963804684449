import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import "./Header/Header.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countries from "../data/countries";

const GuestEnquiryForm = ({ setIsLoading, formId, ...props }) => {
  
  const [stepForm, setStepForm] = useState(1);

  const [form, setForm] = useState({
    // Step 1
    input_11: props.location?.state
      ? props.location.state.room === undefined ||
        props.location.state.room === "" ||
        props.location.state.room === null
        ? ""
        : props.location.state.room
      : "Luxury Suites",

    // Step 2
    input_36: "",
    input_37: "",
    input_39: "",

    // Step 3
    input_19: "",
    input_20: "",

    // Step 4
    input_26: "",
    input_25: "",
    input_27: "",
    input_28: "",
    input_44: "",
    input_43: "",
    
  });

  const [formError, setFormError] = useState({
    input_11: "",

    // Step 2
    input_36: "",
    input_37: "",
    input_39: "",

    // Step 3
    input_19: "",
    input_20: "",

    // Step 4
    input_26: "",
    input_25: "",
    input_27: "",
    input_28: "",
    input_44: "",
    input_43: "",
  });

  const [nxtDate, setNxtDate] = useState("");
  const [checked, setChecked] = useState(false);
  
  useEffect(() => {
    document.body.classList.remove("booking-menu-open");
  }, []);

  return (
    <div className="scroll-form-main">
      <div className="top-info flex flex-wrap items-center justify-between">
        <h3 className="text-white lg:text-30">
          {" "}
          Guest Enquiry
        </h3>
      </div>
      <div className={`gf-step ${"step_" + stepForm}`}></div>
      <form className="lg:mt-30 mt-20">
        <div className="form-wrapper">
          {stepForm === 1 && (
            <div className="form-row">
              <h6 className="text-white capitalize lg:text-20">
                Select Your Accommodation
              </h6>
              <div className="flex flex-wrap lg:mx-minus-20">
                <div className="lg:w-12/12 w-full lg:px-20">
                  <div className="form-group grid grid-cols-1 mdscreen:grid-cols-1  gap-x-20 gap-y-2 relative overflow-hidden lg:mt-30 mt-10">
                    <div className="radio-bx ">
                      <input
                        type="radio"
                        value="Luxury Suites"
                        checked={
                          form.input_11 === "Luxury Suites"
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            input_11: e.target.value,
                          });
                          setFormError({
                            ...formError,
                            input_11: "",
                          });
                        }}
                        id="luxurySuites"
                      />
                      <label
                        htmlFor="luxurySuites"
                        className="text-dark font-400 text-16"
                      >
                        <h4> Luxury Suites </h4>
                      </label>
                    </div>
                    <div className="radio-bx ">
                      <input
                        type="radio"
                        value="Private House"
                        checked={form.input_11 === "Private House"}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            input_11: e.target.value,
                          });
                          setFormError({
                            ...formError,
                            input_11: "",
                          });
                        }}
                        id="privateHouse"
                      />
                      <label
                        htmlFor="privateHouse"
                        className="text-dark font-400 text-16"
                      >
                        <h4> Private House </h4>
                      </label>
                    </div>
                  </div>
                  {formError.input_11 && (
                    <div className="error mt-20">
                      <span className="text-red">
                        {formError.input_11}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {stepForm === 2 && (
            <div className="form-row">
              <h6 className="text-white lg:text-20">
                When will you be arriving?
              </h6>
              <div className="flex flex-wrap lg:mx-minus-20">
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-15"
                      htmlFor=""
                    >
                      Arrival date*
                      <DatePicker
                        className="enquiry-date-picker"
                        selected={form.input_36}
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        onChange={(date) => {
                          setForm({
                            ...form,
                            input_36: date,
                          });

                          var date = new Date(date);
                          date.setDate(date.getDate() + 1);
                          setNxtDate(date);
                          setFormError({
                            ...formError,
                            input_36: "",
                          });
                        }}
                      />
                    </label>
                  </div>

                  {formError.input_36 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_36}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-15"
                      htmlFor=""
                    >
                      Departure date*
                      <DatePicker
                        selected={form.input_37}
                        disabled={
                          form.input_10 === "" ? true : false
                        }
                        minDate={nxtDate}
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setForm({
                            ...form,
                            input_37: date,
                          });
                          setFormError({
                            ...formError,
                            input_37: "",
                          });
                        }}
                      />
                    </label>
                  </div>
                  {formError.input_37 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_37}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="lg:w-6/12 w-full">
                <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                  <div className="radio-bx checkbox-bx">
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setForm({
                          ...form,
                          input_39: e.target.value,
                        });
                      }}
                      id="input_39"
                    />
                    <label
                      htmlFor="input_39"
                      className="text-dark text-16"
                    >
                      I am unsure of my exact dates
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {stepForm === 3 && (
            <div className="form-row">
              <h6 className="text-white lg:text-20">
                Who will you be travelling with?
              </h6>
              <div className="flex flex-wrap lg:mx-minus-20">
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-15"
                      htmlFor=""
                    >
                      Number of Adults*
                    </label>
                    <select
                      name=""
                      id=""
                      value={form.input_19}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_19: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_19: "",
                        });
                      }}
                    >
                      <option value="">Select number...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>

                  {formError.input_19 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_19}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-15"
                      htmlFor=""
                    >
                      Number Of Children*
                    </label>
                    <select
                      name=""
                      value={form.input_20}
                      id=""
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_20: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_20: "",
                        });
                      }}
                    >
                      <option value="">Select number...</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  {formError.input_20 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_20}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {stepForm === 4 && (
            <div className="form-row">
              <h6 className="text-white lg:text-20">
                Guest Information
              </h6>
              <div className="flex flex-wrap lg:mx-minus-20 ">
                <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Name*
                    </label>
                    <input
                      type="text"
                      value={form.input_26}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_26: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_26: "",
                        });
                      }}
                      placeholder="Type here..."
                    />
                  </div>

                  {formError.input_26 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_26}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Surname*
                    </label>
                    <input
                      type="text"
                      value={form.input_25}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_25: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_25: "",
                        });
                      }}
                      placeholder="Type here..."
                    />
                  </div>
                  {formError.input_25 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_25}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Email Address*
                    </label>
                    <input
                      type="text"
                      value={form.input_27}
                      onBlur={(e) => {
                        var value = e.target.value;
                        var pattern =
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                        if (!pattern.test(value)) {
                          setForm({ ...form, input_27: "" });
                          setFormError({
                            ...formError,
                            input_27: "Please enter valid email.",
                          });
                        }
                      }}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_27: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_27: "",
                        });
                      }}
                      placeholder="Type here..."
                    />
                  </div>
                  {formError.input_27 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_27}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Phone Number*
                    </label>
                    <input
                      type="number"
                      value={form.input_28}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_28: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_28: "",
                        });
                      }}
                      placeholder="Type here..."
                    />
                  </div>
                  {formError.input_28 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_28}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Country travelling from*
                    </label>
                    <select
                      value={form.input_44}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_44: e.target.value,
                        });
                        setFormError({
                          ...formError,
                          input_44: "",
                        });
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {formError.input_44 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_44}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lg:w-6/12 w-full lg:px-20">
                  <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                    <label
                      className="w-full text-white text-6"
                      htmlFor=""
                    >
                      Preferred method of contact*
                    </label>
                    <select
                      value={form.input_43}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          input_43: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Option...</option>
                      <option value="Phone">Phone</option>
                      <option value="Email">Email</option>
                    </select>
                  </div>
                  {formError.input_43 && (
                    <div className="error">
                      <span className="text-red">
                        {formError.input_43}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={`form-btn flex lg:mt-50 mt-20 items-center ${stepForm === 1 ? "justify-end" : "justify-between"
              }`}
          >
            {stepForm > 1 && (
              <a
                href="#"
                onClick={() => {
                  setIsLoading((isLoding) => {
                    document.body.classList.add("loading");
                    isLoding = true;
                  });
                  setStepForm((stepForm) => stepForm - 1);
                  setIsLoading((isLoding) => {
                    document.body.classList.remove("loader");
                    isLoding = false;
                  });
                }}
                className="btn-link white  cursor-pointer"
              >
                Previous step
              </a>
            )}
            {stepForm !== 4 ? (
              <a
                onClick={() => {
                  if (stepForm === 1) {
                    setFormError({
                      ...formError,
                      input_11:
                        form.input_11 === ""
                          ? "Please select your accommodation"
                          : "",
                    });

                    if (form.input_11 !== "") {
                      document.body.classList.add("loader");
                      setIsLoading(true);
                      setStepForm((stepForm) => stepForm + 1);
                      document.body.classList.remove("loader");
                      setIsLoading(false);
                    }
                  } else if (stepForm === 2) {
                    setFormError({
                      ...formError,
                      input_36: form?.input_36 === "" ? "Please select arrival date" : "",
                      input_37: form?.input_37 === "" ? "Please select departure date" : "",
                    })
                    if (form?.input_37 !== "" && form?.input_37 != "") {
                      document.body.classList.add("loader");
                      setIsLoading(true);
                      setStepForm((stepForm) => stepForm + 1);
                      document.body.classList.remove("loader");
                      setIsLoading(false);
                    }
                  } else if (stepForm === 3) {
                    setFormError({
                      ...formError,
                      input_19:
                        form.input_19 === ""
                          ? "Please select number of adults."
                          : "",
                      input_20:
                        form.input_20 === ""
                          ? "Please select number of children."
                          : "",
                    });

                    if (form.input_19 !== "" && form.input_20 !== "") {
                      document.body.classList.add("loader");
                      setIsLoading(true);
                      setStepForm((stepForm) => stepForm + 1);
                      document.body.classList.remove("loader");
                      setIsLoading(false);
                    }
                  }
                }}
                className="button btn-transparent cursor-pointer"
              >
                Continue
              </a>
            ) : (
              <a
                href="#"
                onClick={() => {
                  var validRegex =
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                  setFormError({
                    ...formError,
                    input_26:
                      form.input_26 === ""
                        ? "Please enter first name."
                        : "",
                    input_25:
                      form.input_25 === ""
                        ? "Please enter surname."
                        : "",
                    input_28:
                      form.input_28 === ""
                        ? "Please enter phone number."
                        : "",
                    input_27:
                      form.input_27 === "" ||
                        !form.input_27.match(validRegex)
                        ? "Please enter valid email."
                        : "",
                    input_44:
                      form.input_44 === ""
                        ? "Please enter country."
                        : "",
                  });

                  if (
                    form.input_26 !== "" &&
                    form.input_25 !== "" &&
                    form.input_27 !== "" &&
                    form.input_28 !== "" &&
                    form.input_44 !== "" &&
                    form.input_43 !== ""
                  ) {
                    document.body.classList.add("loader");
                    setIsLoading(true);

                    if (form.input_36) {
                      form.input_36 =
                        form.input_36.getMonth() +
                        1 +
                        "/" +
                        form.input_36.getDate() +
                        "/" +
                        form.input_36.getFullYear();
                    }

                    if (form.input_37) {
                      form.input_37 =
                        form.input_37.getMonth() +
                        1 +
                        "/" +
                        form.input_37.getDate() +
                        "/" +
                        form.input_37.getFullYear();
                    }

                    const requestOptions = {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(form),
                    };
                    const url = `${process.env.GATSBY_SITE_URL}wp-json/gf/v2/forms/${formId}/submissions`;
                    setFormError({
                      ...formError,
                      input_26: "",
                    });
                    fetch(url, requestOptions).then((response) => {
                      if (response.status === 200) {
                        document.body.classList.remove("loader");
                        setIsLoading(false);
                        navigate("/thank-you");
                      } else {
                        setStepForm(1);
                        setFormError({
                          ...formError,
                          input_11: "Please try again.",
                        });
                      }
                    });
                  }
                }}
                className="button btn-gold cursor-pointer"
              >
                SUBMIT
              </a>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default GuestEnquiryForm;
export const GuestEnquiryFormFragment = graphql`
  fragment GuestEnquiryFormFragment on WpPage_Pagecontent_PageContent_GuestEnquiryForm {
    formShortcode
    sideImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2000, quality: 90)
    }
  }
`;