import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, lang, title, seo, seoData, acfSEO }) => {
  const { wp, allWp } = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        generalSettings {
          title
          description
        }
      }
      allWp {
        ...seoAllFragment
      }
    }
  `);

  // create convenience vars
  const staticSEO = allWp.edges[0].node.seo;

  // use values or set some defaults
  let meta = [];
  const metaDescription =
    description || seo?.metaDesc || wp.generalSettings?.description;
  const defaultTitle = seo?.title
    ? seo.title
    : wp.generalSettings?.title
    ? wp.generalSettings?.title
    : title;

  // set up robots meta
  let robots = [];
  seo?.metaRobotsNoindex
    ? robots.push(seo?.metaRobotsNoindex)
    : robots.push("index");
  seo?.metaRobotsNofollow
    ? robots.push(seo?.metaRobotsNofollow)
    : robots.push("follow");

  if (robots.length) {
    meta.push({
      name: "robots",
      content: robots.join(", ").replace(/^,|,$/g, "").trim(),
    });
  }

  // set up facebook images
  if (seo?.opengraphImage?.localFile?.childImageSharp) {
    const src =
      seo.opengraphImage.localFile.childImageSharp.gatsbyImageData.images
        .fallback.src;
    meta.push({ name: "og:image", src });
  } else if (staticSEO.social.facebook.defaultImage?.localFile) {
    const src =
      staticSEO.social.facebook.defaultImage.localFile.childImageSharp
        .gatsbyImageData.images.fallback.src;
    meta.push({ name: "og:image", content: src });
  }

  // set up twitter images
  if (seo?.twitterImage?.localFile?.childImageSharp) {
    const src = `${seo.twitterImage.localFile.childImageSharp.gatsbyImageData.images?.fallback.src}`;
    meta.push({ name: "twitter:image", content: src });
  }
  console.log(seo);
  // set up canonical url
  const getCanonical = (canonical) => {
    return canonical ? (
      <link
        rel="canonical"
        href={
          canonical.startsWith("/")
            ? `${process.env.SITE_URL}${canonical}`
            : canonical.replace(
                process.env.GATSBY_API_URL,
                process.env.SITE_URL
              )
        }
      />
    ) : null;
  };
  console.log(getCanonical);

  // set up schema data
  const getPageSchema = () => {
    return seoData ? (
      <script type="application/ld+json">{seoData}</script>
    ) : null;
  };

  // return the seo stuff
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seo?.opengraphTitle || defaultTitle,
        },
        {
          property: `og:description`,
          content: seo?.opengraphDescription || metaDescription,
        },
        {
          property: `og:type`,
          content: seo?.opengraphType || `website`,
        },
        {
          property: `og:url`,
          content: seo?.opengraphUrl || ``,
        },
        {
          property: `og:site_name`,
          content: seo?.opengraphSiteName || defaultTitle,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: staticSEO.social.twitter.username
            ? `@${staticSEO.social.twitter.username}`
            : ``,
        },
        {
          name: `twitter:title`,
          content: seo?.twitterTitle || defaultTitle,
        },
        {
          name: `twitter:description`,
          content: seo?.twitterDescription || metaDescription,
        },
      ].concat(meta)}
    >
      {getCanonical(seo?.canonical)}
      {getPageSchema(acfSEO?.seoPageSchema)}
    </Helmet>
  );
};
export default SEO;
